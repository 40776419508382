@import "./config.min.scss";

.light {
    .menu {
        .left {
            .icon-logo {
                svg {
                    .letter-t,
                    .letter-e,
                    .letter-a,
                    .letter-m,
                    .symbol-point {
                        fill: $orange;
                    }

                    .letter-w,
                    .letter-o,
                    .letter-r,
                    .letter-k {
                        fill: $blue;
                    }
                    
                }
            }

            .menu-responsive {
                .button-menu-responsive {
                    background-color: rgb(255, 255, 255);

                    &:hover {
                        background-color: rgb(240 240 240 / 0.8);
                        transition: background-color .5s;
                    }
                   
                    svg {
                        path {
                            fill: rgb(75 85 99 / 0.6);
                        }
                    }
                }

                .content-menu-responsive {
                    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                    background-color: rgb(255, 255, 255);

                    .scroll-menu-overlay {
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            background: rgb(249 250 251 / 0.9);
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            &:hover {
                                background: rgb(200 200 200 / 0.4);
                            }
                        }

                        .divider-item-menu {
                            background-color: rgba(92, 95, 104, 0.1);
                        }

                        .item-menu {
                            &:hover {
                                background-color: rgb(200 200 200 / 0.1);
                                transition: background-color .5s;
                            }
    
                            &:hover > .item-menu-content > .left > .item-menu-icon {
                                background-color: rgb(200 200 200 / 0.3);
                                transition: background-color .5s;
                            }
    
                            .left {
                                .item-menu-icon {
                                    background-color: rgb(249 250 251 / 0.9);
    
                                        
                                    svg {
                                        path {
                                            fill: rgb(75 85 99 / 0.6);
                                        }
                                    }
                                }
                            }
    
                            .right {
                                .item-menu-title {
                                    color: rgb(17 24 39 / 0.7);
                                }
    
                                .item-menu-description {
                                    color: rgb(75 85 99 / 0.8);
                                }
                            }
                        }
                    }
                }
            }

            .overlay-menu-responsive {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        .middle {
            .item-menu {
                color: rgb(17 24 39 / 0.6);
                border-bottom: 1px solid transparent;

                &:hover {
                    color: rgb(17 24 39 / 0.8);
                    transition: color .5s;
                    border-bottom: 1px solid rgb(17 24 39 / 0.8);
                    transition: border-bottom .5s;
                }
            }
        }

        .right {
            .button-dark-mode {
                background-color: #0054B9;

                svg {
                    path {
                        fill: #0054B9;
                    }
                }
            }
        }
    }

    .error-404 {
        .title {
            color: rgb(17 24 39 / 0.9);
        }

        .description {
            color: rgb(75 85 99 / 0.9);
        }
    }

    .menu-error-404 {
        .card-error-link{

            .card-link-background { 
                border-bottom: 1px solid rgb(17 24 39 / 0.1);

                .left {
                    border: 1px solid #e5e7eb;

                    .icon-list {
                        path {
                            fill: #0065DD;
                        }
                    }
                }

                .right {
                    .title {
                        color: rgb(17 24 39 / 0.9);
                    }

                    .description {
                        color: rgb(75 85 99 / 0.9);
                    }
                }
            }            
        }
    }

    .go-to-home {
        .card-button {
            color: #0065DD;

            svg {
                path {
                    fill: #0065DD;
                }
            }
        }
    }

    .informative-guide-landing {
        .title {
            color: rgb(17 24 39 / 0.9);
        }

        .description {
            color: rgb(75 85 99 / 0.9);
        }

        .control-button { 
            .button-ebook {
                background-color: #0065DD;
                color: #fff;
                transition: all .2s ease-out;

                &:hover {
                    background-color: #5496FF;
                    transition: background-color .5s;
                }
            }

            .card-button {
                color: #0065DD;

                svg {
                    path {
                        fill: #0065DD;
                    }
                }
            }
        }
    }

    .content-informative-guide {
        .legends {
            color:rgba(26, 90, 226, 0.9);
        }

        .title {
            color: rgb(17 24 39 / 0.9);
        }

        .description-primary,
        .description-secondary {
            color: rgb(75 85 99 / 0.9);
        }

        .subtitle {
            color: rgb(17 24 39 / 0.9);
        }

        .list {
            li {
                color: rgb(75 85 99 / 0.9);

                svg {
                    path {
                        fill: rgb(75 85 99 / 0.9);
                    }
                }
            }
        }

        .cards-defined {

            .card-defined {
                &:not(:last-child){
                    @media (width < $deviceWidthM) {
                        border-bottom: 1px solid rgb(17 24 39 / 0.1);
                    }
                }

                .labels-card {

                    .legend-card {
                        color: rgb(75 85 99 / 0.9);
                    }

                    .label-card {
                        color: rgb(75 85 99 / 0.6);
                        background-color: rgb(249 250 251 / 0.9);
                    }
                }

                .title-card {
                    color: rgb(17 24 39 / 0.8);
                }

                .description-card {
                    color: rgb(75 85 99 / 0.9);
                }
            }

        }
    }

    .report {
        .right {
            .title {
                color: rgb(17 24 39 / 0.9);
            }

            .description {
                color: rgb(75 85 99 / 0.9);
            }

            .contact {
                color: rgb(75 85 99 / 0.9);

                .link-mail {
                    color: #0065DD;

                    &:hover {
                        color: #5496FF;
                        transition: color .5s;
                    }
                }
            }

            .control-button { 
                .button-report {
                    background-color: #0065DD;
                    color: #fff;
                    transition: all .2s ease-out;
    
                    &:hover {
                        background-color: #5496FF;
                        transition: background-color .5s;
                    }
                }

                .card-button {
                    color: #0065DD;
    
                    svg {
                        path {
                            fill: #0065DD;
                        }
                    }
                }
            }
            
        }
    }

    .landing {
        .title {
            color: rgb(17 24 39 / 0.9);
        }

        .description {
            color: rgb(75 85 99 / 0.9);
        }

        .link-reditect {
            .card-button {
                color: #0065DD;

                svg {
                    path {
                        fill: #0065DD;
                    }
                }
            }
        }
    }
    
    .home {
        .title {
            color: rgb(17 24 39 / 0.9);
        }
    
        p {
            color: rgb(75 85 99 / 0.9);
        }
    }
    
    .glossary {
        .subtitle {
            color: #0065DD;
        }
    
        .title {
            color: rgb(17 24 39 / 0.9);
        }
    
        .cards {
            .card {
                .card-arrow {
                    path {
                        fill: rgba(26, 90, 226, 0.9);
                    }
                }

                .card-title {
                    color: rgba(26, 90, 226, 0.9);
                }
    
                .card-description {
                    color: rgb(17 24 39 / 0.9);
                }
            }
        }
    }
    
    .academic-modules {
        .subtitle {
            color: #0065DD;
        }
    
        .title {
            color: rgb(17 24 39 / 0.9);
        }
    
        .description {
            color: rgb(17 24 39 / 0.7);
        }
        
        .card-academic-module {
            border-bottom: 1px solid rgb(17 24 39 / 0.1);
            
            .card-academic-module-header {
                .card-arrow {
                    path {
                        fill: #0065DD;
                    }
                }
    
                p {
                    color: rgb(17 24 39 / 0.8);
                }
            }
    
            .card-academic-module-body {
                .box-actions {
                    .card-button {
                        color: #0065DD;
                    }
                }
    
                .content-multi-text {
                    color: rgb(17 24 39 / 0.8);
                }
            }
        }
    }

    .footer {
        .menu-footer {
            .item-menu {
                color: rgb(17 24 39 / 0.6);

                &:hover {
                    color: rgb(17 24 39 / 0.8);
                }
            }
        }

        .social-media {
            .item-social-media {
                svg {
                    path {
                        fill: rgb(75 85 99 / 0.7);
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: rgb(75 85 99 / 0.9);
                        }
                    }
                }
            }
        }

        .copyright {
            color: rgb(75 85 99 / 0.7);
        }
    }
}
