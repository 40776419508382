@import "./config.min.scss";

$dark: rgb(18, 23, 34);

.dark {
    background-color: $dark;

    .menu {
        .left {

            .icon-logo {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            .menu-responsive {
                .button-menu-responsive {
                    background-color: rgb(18, 23, 34);

                    &:hover {
                        background-color: rgba(185, 187, 192, 0.2);
                        transition: background-color .5s;
                    }

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                .content-menu-responsive {
                    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                    background-color: $dark;

                    .scroll-menu-overlay {
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            background: rgba(83, 83, 83, 0.1);
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            &:hover {
                                background: rgba(82, 82, 82, 0.4);
                            }
                        }

                        .divider-item-menu {
                            background-color: rgba(164, 164, 164, 0.1);
                        }

                        .item-menu {
                            &:hover {
                                background-color: rgba(82, 82, 82, 0.1);
                                transition: background-color .5s;
                            }
    
                            &:hover > .item-menu-content > .left > .item-menu-icon {
                                background-color: rgba(185, 187, 192, 0.2);
                                transition: background-color .5s;
                            }
    
                            .left {
                                .item-menu-icon {
                                    background-color: rgba(83, 83, 83, 0.1);
    
                                        
                                    svg {
                                        path {
                                            fill: rgba(185, 187, 192, 0.5);
                                        }
                                    }
                                }
                            }
    
                            .right {
                                .item-menu-title {
                                    color: rgb(255 255 255 / 0.8);
                                }
    
                                .item-menu-description {
                                    color: rgb(255 255 255 / 0.6);
                                }
                            }
                        }
                    }
                }
            }   

            .overlay-menu-responsive {
                background-color: rgba(100, 100, 100, 0.3);
            }
        }

        .middle {
            .item-menu {
                color: rgb(255 255 255 / 0.6);
                border-bottom: 1px solid transparent;

                &:hover {
                    color: rgb(255 255 255 / 0.8);
                    transition: color .5s;
                    border-bottom: 1px solid rgb(255 255 255 / 0.8);
                    transition: border-bottom .5s;
                }
            }
        }

        .right {
            svg {
                path {
                    fill: #5496FF;
                }
            }

            .button-dark-mode {
                background-color: #5496FF;
            }
        }
    }

    .error-404 {
        .title {
            color: rgb(255 255 255 / 0.9);
        }

        .description {
            color: rgb(255 255 255 / 0.7);
        }
    }

    .menu-error-404 {
        .card-error-link{

            .card-link-background { 
                border-bottom: 1px solid rgb(255 255 255 / 0.1);

                .left {
                    border: 1px solid rgb(255 255 255 / 0.1);

                    .icon-list {
                        path {
                            fill: #5496FF;
                        }
                    }
                }

                .right {
                    .title {
                        color: rgb(255 255 255 / 0.9);
                    }

                    .description {
                        color: rgb(255 255 255 / 0.7);
                    }
                }
            }            
        }
    }

    .go-to-home {
        .card-button {
            color: #5496FF;

            svg {
                path {
                    fill: #5496FF;
                }
            }
        }
    }

    .informative-guide-landing {
        .title {
            color: rgb(255 255 255 / 0.9);
        }

        .description {
            color: rgb(255 255 255 / 0.7);
        }

        .control-button {
            .button-ebook {
                background-color: #5496FF;
                color: #fff;
                transition: all .2s ease-out;

                &:hover {
                    background-color: #0065DD;
                    transition: background-color .5s;
                }
            }

            .card-button {
                color: #5496FF;

                svg {
                    path {
                        fill: #5496FF;
                    }
                }
            }
        }
    }

    .content-informative-guide {
        .legends {
            color: rgb(255 255 255 / 0.6);
        }

        .title {
            color: rgb(255 255 255 / 0.9);
        }

        .description-primary,
        .description-secondary {
            color: rgb(255 255 255 / 0.8);
        }

        .subtitle {
            color: rgb(255 255 255 / 0.9);
        }

        .list {
            li {
                color: rgb(255 255 255 / 0.7);

                svg {
                    path {
                        fill: rgb(255 255 255 / 0.7);
                    }
                }
            }
        }

        .cards-defined {

            .card-defined {
                &:not(:last-child){
                    @media (width < $deviceWidthM) {
                        border-bottom: 1px solid rgb(255 255 255 / 0.2);
                    }
                }

                .labels-card {

                    .legend-card {
                        color: rgb(255 255 255 / 0.8);
                    }

                    .label-card {
                        color: rgb(255 255 255 / 0.6);
                        background-color: rgb(255 255 255 / 0.1);
                    }
                }

                .title-card {
                    color: rgb(255 255 255 / 0.9);
                }

                .description-card {
                    color: rgb(255 255 255 / 0.8);
                }
            }

        }
    }

    .report {
        .right {
            .title {
                color: rgb(255 255 255 / 0.9);
            }

            .description {
                color: rgb(255 255 255 / 0.7);
            }

            .contact {
                color: rgb(255 255 255 / 0.7);

                .link-mail {
                    color: #5496FF;

                    &:hover {
                        color: #0065DD;
                        transition: color .5s;
                    }
                }
            }

            .control-button {
                .button-report {
                    background-color: #5496FF;
                    color: #fff;
                    transition: all .2s ease-out;
    
                    &:hover {
                        background-color: #0065DD;
                        transition: background-color .5s;
                    }
                }

                .card-button {
                    color: #5496FF;
    
                    svg {
                        path {
                            fill: #5496FF;
                        }
                    }
                }
            }
        }
    }

    .landing {
        .title {
            color: rgb(255 255 255 / 0.9);
        }

        .description {
            color: rgb(255 255 255 / 0.7);
        }

        .link-reditect {
            .card-button {
                color: #5496FF;

                svg {
                    path {
                        fill: #5496FF;
                    }
                }
            }
        }
    }
    
    .home {
        .title {
            color: rgb(255 255 255 / 0.9);
        }
    
        p {
            color: rgb(255 255 255 / 0.9);
        }
    }
    
    .glossary {
        .subtitle {
            color: #5496FF;
        }
    
        .title {
            color: rgb(255 255 255 / 0.9);
        }
    
        .cards {
            .card {
                .card-arrow {
                    path {
                        fill: #5496FF;
                    }
                }

                .card-title {
                    color: #5496FF;
                }
    
                .card-description {
                    color: rgb(255 255 255 / 0.9);
                }
            }
        }
    }
    
    .academic-modules {
        .subtitle {
            color: #5496FF;
        }
    
        .title {
            color: rgb(255 255 255 / 0.9);
        }
    
        .description {
            color: rgb(255 255 255 / 0.7);
        }
        
        .card-academic-module {
            border-bottom: 1px solid rgb(255 255 255 / 0.1);
            
            .card-academic-module-header {
    
                .card-arrow {
                    path {
                        fill: #5496FF;
                    }
                    
                }
    
                p {
                    color: rgb(255 255 255 / 0.8);
                }
            }
    
            .card-academic-module-body {
                .box-actions {
                    .card-button {
                        color: #5496FF;
                    }
                }
    
                .content-multi-text {
                    color: rgb(255 255 255 / 0.8);
                }
            }
        }
    }

    .footer {
        .menu-footer {
            .item-menu {
                color: rgb(255 255 255 / 0.6);

                &:hover {
                    color: rgb(255 255 255 / 0.8);
                }
            }
        }

        .social-media {
            .item-social-media {
                svg {
                    path {
                        fill: rgb(255 255 255 / 0.7);
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: rgb(255 255 255 / 0.9);
                        }
                    }
                }
            }
        }

        .copyright {
            color: rgb(255 255 255 / 0.7);
        }
    }
}
