@import "./config.min.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.menu {
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-top: 60px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media (width < $deviceWidthM) {
        justify-content: space-between;
    }

    @media (width >= $deviceWidthM) {
        justify-content: center;
    }

    .left {
        width: 260px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .menu-responsive {
            position: relative;

            .button-menu-responsive {
                width: 40px;
                height: 40px;
                display: flex;
                vertical-align: middle;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: none;
                cursor: pointer;
                z-index: 3;
                position: relative;
            }
    
            .content-menu-responsive {
                position: absolute;
                left: 0;
                top: 45px;
                border-radius: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                padding: 20px 15px;
                z-index: 3;
                animation: fade-in-menu-responsive .8s;
                max-height: 80vh;

                @media (width < 500px) {
                    width: 90vw;
                }

                @media (width >= 500px) {
                    width: 25rem;
                }

                .scroll-menu-overlay {
                    overflow-y: auto;
                    overflow-x: hidden;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    
                    &::-webkit-scrollbar-track {
                        border-radius: 10px;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                    }

                    .divider-item-menu {
                        height: 1px;
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 19px;
                        margin-bottom: 20px;
                    }

                    @keyframes fade-in-menu-responsive {
                        from {
                            transform: translateY(30px);
                            opacity: 0;
                        }
    
                        to {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
    
                    .item-menu {
                        width: 100%;
                        padding: 1rem;
                        display: flex;
                        align-items: center;
                        border-radius: 0.5rem;
                        text-decoration: none;
    
                        .item-menu-content {
                            display: flex;
                            align-items: flex-start;
                            width: 100%;
                            gap: 30px;
    
                            .left {
                                width: 2.75rem;
    
                                .item-menu-icon {
                                    border-radius: 0.5rem;
                                    width: 2.75rem;
                                    height: 2.75rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
    
                            .right {
                                width: calc(100% - 2.75rem);
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
    
                                .item-menu-title {
                                    font-family: $fontFamily;
                                    font-size: 1rem;
                                    line-height: 1rem;
                                    font-weight: 500;
                                }
    
                                .item-menu-description {
                                    font-family: $fontFamily;
                                    font-size: 0.875rem;
                                    line-height: 1.5rem;
                                    font-weight: 300;
                                }
                            }
                        }
                    }

                }

                
            }
        }

        .overlay-menu-responsive {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            backdrop-filter: blur(10px);
            animation: fade-in-overlay .2s;
            z-index: 2;

            @keyframes fade-in-overlay {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }
        }
        
    }

    .middle {
        @media (width >= $deviceWidthM) {
            display: flex;
        }

        @media (width < $deviceWidthM) {
            display: none;
        }

        width: 80%;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        gap: 50px;

        .item-menu {
            text-decoration: none;
            font-family: $fontFamily;
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-weight: 500;
            height: 30px;
            display: flex;
        }
    }

    .right {
        width: 200px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .button-dark-mode {
            cursor: pointer;
            border: 1px solid transparent;
            width: 50px;
            height: 30px;
            padding: 3px;
            border-radius: 20px;
            position: relative;
            display: flex;
            vertical-align: middle;
            align-items: center;
            z-index: 1;
            
            &:focus {
                outline: none;
            }

            .dark-mode-enabled {
                left: 22px;
            }

            .dark-mode-disabled {
                left: 5px;
            }

            .circle-selected {
                position: absolute;
                width: 22px;
                height: 22px;
                display: flex;
                background-color: #fff;
                border-radius: 50%;
            }
        }
    }
}

.error-404 {
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;

    .title {
        font-family: $fontFamily;
        font-size: 3.75rem;
        line-height: 1;
        margin-top: -30px;
    }

    .description {
        font-family: $fontFamily;
        font-size: 1.125rem;
        line-height: 2rem;
        font-weight: 300;
        margin-top: 20px;
    }
}

.menu-error-404 {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;

    .card-error-link{

        .card-link-background {
            display: flex;
            vertical-align: middle;
            align-items: center;
            width: 100%;
            gap: 20px;
            padding-bottom: 30px;
            padding-top: 30px;
            text-decoration: none;
            
            .left {
                width: 45px;
                height: 45px;
                border-radius: 0.5rem;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
                display: flex;
                vertical-align: middle;
                align-items: center;
                justify-content: center;
            }
    
            .right {
                width: calc(100% - 40px);
    
                .title {
                    font-family: $fontFamily;
                    font-size: 1rem;
                    line-height: 1.75rem;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
    
                .description {
                    font-family: $fontFamily;
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                    font-weight: 300;
                }
            }
        }
    }
}

.go-to-home {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
    margin-top: 30px;

    .card-button {
        text-decoration: none;
        font-family: $fontFamily;
        font-size: 14px;
        font-weight: 300;
        background-color: transparent;
        border: none;
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        cursor: pointer;

        .direction-left {
            transform: rotate(180deg);
        }

        &:hover {
            text-decoration: underline;
            transition: all 0.2s;
        }
    }
}

.informative-guide-landing {
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 200px;

    .title {
        font-family: $fontFamily;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 50px;

        @media (width < $deviceWidthS) {
            width: 80%;
            font-size: 3rem;
            line-height: 3.5rem;
        }

        @media (width >= $deviceWidthS) {
            width: 60%;
            font-size: 3.75rem;
            line-height: 4rem;
        }
    }

    .description {
        font-family: $fontFamily;
        font-weight: 300;
        width: 70%;
        text-align: center;
        margin-bottom: 100px;

        @media (width < $deviceWidthS) {
            width: 80%;
            font-size: 1rem;
            line-height: 1.75rem;
        }

        @media (width >= $deviceWidthS) {
            width: 60%;
            font-size: 1.125rem;
            line-height: 2rem;
        }
    }

    .control-button {
        display: flex;
        vertical-align: middle;
        align-items: center;
        gap: 30px;

        .button-ebook {
            font-family: $fontFamily;
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 15px 20px;
            border-radius: 8px;
            text-decoration: none;
            font-weight: 600;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            display: flex;
            width: max-content;
            border: none;
            cursor: pointer;
        }

        .card-button {
            text-decoration: none;
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: 300;
            background-color: transparent;
            border: none;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                transition: text-decoration 0.2s ease-in;
            }
        }
    }
}

.content-informative-guide {
    display: flex;
    vertical-align: middle;
    align-items: flex-start;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;

    @keyframes fade-in-scroll {
        from {
            opacity: 0;
            scale: 0%;
            transform: translateY(200px);
        }
        
        to {
            scale: 100%;
            opacity: 1;
            transform: translateY(0);
            transition: transform .2s;
        }
    }

    @media (width < $deviceWidthS) {
        width: 80%;
    }

    @media (width >= $deviceWidthS) {
        width: 65%;
    }

    .legends {
        font-family: $fontFamily;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 300;
        margin-bottom: 5px;
        view-timeline-name: --element;
        view-timeline-axis: block;
        animation-timeline: --element;
        animation-name: fade-in-scroll;
        animation-range: entry 10% cover 50%;
    }

    .title {
        font-family: $fontFamily;
        font-size: 2.25rem;
        line-height: 2.5rem;
        margin-bottom: 70px;
        font-weight: 700;

        @media (width < $deviceWidthS) {
            width: 80%;
        }

        @media (width >= $deviceWidthS) {
            width: 60%;
        }
    }

    .description-primary {
        font-family: $fontFamily;
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 200;
    }

    .description-secondary {
        font-family: $fontFamily;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 200;
        view-timeline-name: --element;
        view-timeline-axis: block;
        animation-timeline: --element;
        animation-name: fade-in-scroll;
        animation-range: entry 10% cover 50%;

        @media (width >= $deviceWidthS) {
            width: 90%;
        }
    }

    .subtitle {
        font-family: $fontFamily;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        margin-top: 60px;
        margin-bottom: 30px;
        view-timeline-name: --element;
        view-timeline-axis: block;
        animation-timeline: --element;
        animation-name: fade-in-scroll;
        animation-range: entry 10% cover 50%;
    }

    .list {
        list-style: none;
        width: 100%;
        display: flex;
        vertical-align: middle;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;

        @media (width < $deviceWidthS) {
            flex-direction: column;
            padding-left: 10%;
        }

        @media (width >= $deviceWidthS) {
            padding-left: 3%;
        }
        
        li {
            view-timeline-name: --element;
            view-timeline-axis: block;
            animation-timeline: --element;
            animation-name: fade-in-scroll;
            animation-range: entry 10% cover 50%;
            font-family: $fontFamily;
            font-size: 1rem;
            line-height: 1.75rem;
            font-weight: 300;
            margin-bottom: 10px;
            display: flex;
            vertical-align: middle;
            align-items: center;
            position: relative;
            padding-left: 30px;

            @media (width < $deviceWidthS) {
                width: 100%;
            }

            @media (width >= $deviceWidthS) {
                width: calc(100% / 2 - 20px);
                
            }

            svg {
                position: absolute;
                left: 0;
            }
        }
    }

    .cards-defined {
        display: flex;
        vertical-align: middle;
        align-items: flex-start;
        margin-bottom: 50px;
        margin-top: 50px;
        flex-wrap: wrap;

        @media (width < $deviceWidthM) {
            flex-direction: column;
            gap: 50px;
        }

        @media (width >= $deviceWidthM) {
            flex-direction: row;
            gap: 70px;
        }

        .card-defined {
            view-timeline-name: --element;
            view-timeline-axis: block;
            animation-timeline: --element;
            animation-name: fade-in-scroll;
            animation-range: entry 10% cover 50%;

            @media (width < $deviceWidthM) {
                width: 100%;
                padding-bottom: 50px;
            }

            @media (width >= $deviceWidthM) {
                width: calc(100% / 3 - 70px);
            }

            .labels-card {
                display: flex;
                vertical-align: middle;
                align-items: flex-start;

                .legend-card {
                    font-family: $fontFamily;
                    font-weight: 600;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                    padding-top: 0.375rem;
                }

                .label-card {
                    font-family: $fontFamily;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                    padding-top: 0.375rem;
                    padding-bottom: 0.375rem;
                    width: max-content;
                    border-radius: 100px;
                }
            }
            
            .title-card {
                font-family: $fontFamily;
                font-size: 1.125rem;
                line-height: 1.5rem;
                font-weight: 600;
                margin-bottom: 30px;
                margin-top: 5px;
            }

            .description-card {
                font-family: $fontFamily;
                font-size: 0.875rem;
                line-height: 1.5rem;
                font-weight: 200;

                b {
                    font-weight: 500;
                }
            }
        }
    }
}

.report {
    margin-bottom: 200px;
    display: flex;
    vertical-align: middle;
    align-items: center;

    @media (width < $deviceWidthS) {
        flex-direction: column;
        margin-top: 50px;
    }

    @media (width >= $deviceWidthS) {
        margin-top: 150px;
    }

    .left {
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (width < $deviceWidthS) {
            width: 85%;

            .img-report-chanell {
                width: 400px;
            }
        }

        @media (width >= $deviceWidthS) and (width < $deviceWidthM) {
            width: 47%;
            
            .img-report-chanell {
                width: 400px;
            }
        }

        @media (width >= $deviceWidthM) {
            width: 47%;
            
            .img-report-chanell {
                width: 500px;
            }
        }
    }

    .right {
        @media (width < $deviceWidthS) {
            width: 75%;
        }

        @media (width >= $deviceWidthS) {
            width: 53%;
            padding-left: 5%;
        }

        .title {
            display: flex;
            vertical-align: middle;
            align-items: flex-start;
            font-family: $fontFamily;
            line-height: 1;
            margin-bottom: 50px;

            @media (width < $deviceWidthS) {
                width: 100%;
                font-size: 2.5rem;
            }

            @media (width >= $deviceWidthS) and (width < $deviceWidthM) {
                font-size: 2.75rem;
                width: 90%;
            }

            @media (width >= $deviceWidthM) {
                font-size: 3.75rem;
                width: 80%;
            }
        }

        .description {
            font-family: $fontFamily;
            line-height: 2rem;
            font-weight: 300;

            @media (width < $deviceWidthS) {
                width: 100%;
                font-size: 1rem;
            }

            @media (width >= $deviceWidthS) and (width < $deviceWidthM) {
                width: 90%;
                font-size: 1.1rem;
            }

            @media (width >= $deviceWidthM) {
                width: 70%;
                font-size: 1.125rem;
            }
        }

        .contact {
            font-family: $fontFamily;
            line-height: 2rem;
            font-weight: 300;
            margin-top: 30px;

            @media (width < $deviceWidthS) {
                width: 100%;
                font-size: 1rem;
            }

            @media (width >= $deviceWidthS) and (width < $deviceWidthM) {
                width: 90%;
                font-size: 1.1rem;
            }

            @media (width >= $deviceWidthM) {
                width: 70%;
                font-size: 1.125rem;
            }
        }

        .control-button {
            display: flex;
            vertical-align: middle;
            align-items: center;
            margin-top: 30px;
            gap: 30px;

            .button-report {
                font-family: $fontFamily;
                font-size: 1rem;
                line-height: 1.25rem;
                padding: 15px 20px;
                border-radius: 8px;
                text-decoration: none;
                font-weight: 600;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
                display: flex;
                width: max-content;
            }

            .card-button {
                text-decoration: none;
                font-family: $fontFamily;
                font-size: 16px;
                font-weight: 300;
                background-color: transparent;
                border: none;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                    transition: text-decoration 0.2s ease-in;
                }
            }
        }
    }
}

.landing {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;

    .left {
        display: flex;
        vertical-align: middle;
        align-items: flex-start;
        flex-direction: column;

        @media (width < $deviceWidthS) {
            width: 75%;
        }

        @media (width >= $deviceWidthS) {
            width: 55%;
            padding-left: 5%;
        }

        .title {
            display: flex;
            vertical-align: middle;
            align-items: flex-start;
            font-family: $fontFamily;
            line-height: 1;
            margin-bottom: 50px;

            @media (width < $deviceWidthS) {
                font-size: 2.5rem;
                width: 100%;
            }

            @media (width >= $deviceWidthS) {
                font-size: 3.75rem;
                width: 80%;
            }
        }

        .description {
            font-family: $fontFamily;
            line-height: 2rem;
            font-weight: 300;

            @media (width < $deviceWidthS) {
                width: 100%;
                font-size: 1rem;
            }

            @media (width >= $deviceWidthS) {
                font-size: 1.125rem;
                width: 70%;
            }
        }

        .link-reditect {
            margin-top: 70px;

            @media (width < $deviceWidthS) {
                width: 100%;
            }

            @media (width >= $deviceWidthS) {
                width: 80%;
            }

            .card-button {
                text-decoration: none;
                font-family: $fontFamily;
                font-size: 16px;
                font-weight: 300;
                background-color: transparent;
                border: none;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
                cursor: pointer;
                float: right;
    
                &:hover {
                    text-decoration: underline;
                    transition: all 0.2s;
                }
    
            }
        }
        
    }

    .right {
        @media (width < $deviceWidthS) {
            display: none;
        }

        width: 45%;
        overflow: hidden;

        img {
            border-radius: 50px;
        }
    }   
}

.home {
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin-top: 200px;

    .title {
        font-family: $fontFamily;
        font-size: 3.75rem;
        line-height: 1;
        text-align: center;
    }

    p {
        font-family: $fontFamily;
        font-size: 1.125rem;
        line-height: 2rem;
        font-weight: 300;
        width: 70%;
        text-align: center;
    }
}

.glossary {
    .subtitle {
        font-family: $fontFamily;
        font-size: 1.15rem;
        line-height: 1.75rem;
        text-align: center;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .title {
        font-family: $fontFamily;
        font-size: 2.25rem;
        line-height: 2.5rem;
        text-align: center;
        margin-bottom: 100px;
    }

    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        row-gap: 60px;

        .card {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 40px;
            padding-right: 20px;
            position: relative;

            .card-arrow {
                position: absolute;
                top: -2px;
                left: 7px;
            }

            .card-title {
                font-family: $fontFamily;
                font-size: 1rem;
                font-weight: 500;
            }

            .card-description {
                font-family: $fontFamily;
                font-size: 16px;
                font-weight: 300;
                line-height: 25px;
            }
        }
    }
}

.academic-modules {
    margin-top: 200px;

    .subtitle {
        font-family: $fontFamily;
        font-size: 1.15rem;
        line-height: 1.75rem;
        text-align: center;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .title {
        font-family: $fontFamily;
        font-size: 2.25rem;
        line-height: 2.5rem;
        text-align: center;
        margin-bottom: 50px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .description {
        font-family: $fontFamily;
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        width: 70%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
    }
    
    .card-academic-module {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        height: 100px;
        
        .card-academic-module-header {
            display: flex;
            vertical-align: middle;
            padding-top: 40px;
            padding-bottom: 40px;
            position: relative;
            vertical-align: middle;
            align-items: flex-start;
            cursor: pointer;
            flex-direction: column;

            .card-arrow {
                position: absolute;
                right: 20px;
                transform: rotate(90deg);
            }

            p {
                font-family: $fontFamily;
                font-size: 19px;
                font-weight: 300;
                line-height: 25px;
                user-select: none;
            }
        }

        .card-academic-module-body {
            padding-bottom: 50px;

            .box-actions {
                margin-bottom: 30px;

                .button-redirect {
                    float: right;
                }
    
                .card-button {
                    text-decoration: none;
                    font-family: $fontFamily;
                    font-size: 16px;
                    font-weight: 300;
                    background-color: transparent;
                    border: none;
                    display: inline-flex;
                    vertical-align: middle;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                        transition: all 0.2s;
                    }
                }
            }

            .content-multi-text {
                font-family: $fontFamily;
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 40px;
            }

            iframe {
                position: relative;
                top: 0;
                left: 0;
                width: 1000px;
                height: 575px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
            }
        }
    }
}

.footer {
    padding-top: 300px;
    padding-bottom: 100px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    gap: 50px;

    .menu-footer {
        display: flex;
        vertical-align: middle;
        align-items: center;

        @media (width < $deviceWidthS) {
            flex-direction: column;
            gap: 20px;
        }

        @media (width >= $deviceWidthS) {
            flex-direction: row;
            gap: 50px;
        }

        .item-menu {
            text-decoration: none;
            font-family: $fontFamily;
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-weight: 400;
            transition: color .5s;
        }
    }

    .social-media {
        display: flex;
        vertical-align: middle;
        align-items: center;
        gap: 20px;

        .item-social-media {
            display: flex;
            vertical-align: middle;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            &:hover {
                svg {
                    path {
                        transition: fill .5s;
                    }
                }
            }
        }
    }
    
    .copyright {
        font-family: $fontFamily;
        line-height: 1.5rem;
        font-weight: 300;
        font-size: 0.875rem;
        text-align: center;

        @media (width < $deviceWidthS) {
            width: 80%;
        }

        @media (width >= $deviceWidthS) {
            width: 100%;
        }
    }
}