@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

$fontFamily: Inter var, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$deviceWidthS: 900px;
$deviceWidthM: 1200px;
$deviceWidthL: 1500px;
$deviceWidthXL: 1700px;

$orange: #FF4900;
$blue: #0065DD;